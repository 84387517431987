import React from "react"
import Banner from "../components/Banner/banner"

const Fail = () => (
  <>
      <Banner
        h1Txt={"Midagi läks kahjuks valesti...."}
        h2Txt={
          "Kirjutage meile e-mail katarinakala0 at gmail.com"
        }
        btnTxt={"Mine tagasi avalehele"}
				btnLink={"/"}
      />
  </>
)

export default Fail
